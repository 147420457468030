import Head from 'next/head';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styles from './ErrorPage.module.css';
import Logo from '../components/Logo';
import Footer from '../components/Footer';

const ErrorPage: React.FC<{
  topBarRightComponent?: React.ReactElement;
  topBarMobileComponent?: React.ReactElement;
}> = ({ children, topBarRightComponent, topBarMobileComponent }) => (
  <Container fluid>
    <Head>
      <title>Learn SQL analytics - supercooldata</title>
      <link rel="icon" href="/favicon.ico" />
    </Head>
    <Row className={styles.mainSection}>
      <Col>
        <Container>
          <Row className={`mx-0 ${styles.topBar}`}>
            <Col className="text-center text-md-left">
              <Logo href="/" />
            </Col>
            <Col className={`d-none d-md-block ${styles.topRight}`}>
              <div className={styles.inner}>{topBarRightComponent}</div>
            </Col>
            <Col className={`d-md-none ${styles.topMobile}`}>
              <div className={styles.inner}>{topBarMobileComponent}</div>
            </Col>
          </Row>
          <Row>
            <Col className={styles.body}>{children}</Col>
          </Row>
        </Container>
      </Col>
    </Row>
    <Footer />
  </Container>
);

export default ErrorPage;
