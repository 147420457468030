import React from 'react';
import * as Sentry from '@sentry/node';

import ErrorPage from './ErrorPage';
import InstrumentedLink from './InstrumentedLink';
import { isApiResponseError } from '../lib/frontend/types';
import { CATEGORIES } from '../lib/universal/types';
import { sendEvent } from '../lib/frontend/stats';

type ErrorBoundaryProps = Record<string, unknown>;

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { error: any }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: any) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    sendEvent(CATEGORIES.ERROR_BOUNDARY, {
      name: 'errorBoundaryCaughtError',
      metadata: {
        error:
          error && typeof error.message === 'string'
            ? error.message
            : String(error),
      },
    });
    Sentry.withScope((scope) => {
      scope.setTag('caughtByComponent', 'ErrorBoundary');
      scope.setTag('errorInfo', JSON.stringify(errorInfo));
      if (isApiResponseError(error)) {
        scope.setTag('errorDetails', JSON.stringify(error.details));
        scope.setTag('errorResStatus', String(error.response.status));
        scope.setTag('errorResStatusText', error.response.statusText);
      }
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error !== null && this.state.error !== undefined) {
      return (
        <ErrorPage>
          <p>
            An error has occurred. An alert with details has been sent so we can
            investigate and we&apos;ll try to fix it as soon as possible.
            Apologies for the inconvenience.
          </p>
          <p>
            If this error persists please contact us at{' '}
            <InstrumentedLink
              href="mailto:hi@supercooldata.com"
              label="error_boundary_hi_email_address"
              category={CATEGORIES.ERROR_BOUNDARY}
            >
              hi@supercooldata.com
            </InstrumentedLink>
          </p>
          <p>
            <InstrumentedLink
              internalHref="/dashboard"
              label="error_boundary_back_to_dashboard_link"
              category={CATEGORIES.ERROR_BOUNDARY}
            >
              Back to Dashboard
            </InstrumentedLink>
          </p>
        </ErrorPage>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
