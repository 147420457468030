import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import { ProjectsApiDerivedResponse } from '../universal/questions';
import {
  CertificatesApiResponse,
  StreamsApiResponse,
} from '../universal/types';
import useApi from './api';
import { useAuth } from './auth';

type StoreContextValue = {
  isLoading: boolean;
  isLoadingCourse: boolean;
  isLoadingStreams: boolean;
  course: ProjectsApiDerivedResponse | undefined;
  certificates: CertificatesApiResponse | undefined;
  streams: StreamsApiResponse | undefined;
  refresh: () => void;
  refreshOnNextRequest: () => void;
  refreshIfPreviouslyRequested: () => void;
};

const StoreContext = createContext<StoreContextValue>({
  isLoading: false,
  isLoadingCourse: false,
  isLoadingStreams: false,
  course: undefined,
  certificates: undefined,
  streams: undefined,
  refresh: () => null,
  refreshOnNextRequest: () => null,
  refreshIfPreviouslyRequested: () => null,
});

export const StoreProvider: React.FC = ({ children }) => {
  const { api } = useApi();
  const { isLoggedIn } = useAuth();

  const [isLoadingCourse, setIsLoadingCourse] = useState(false);
  const [isLoadingCertificates, setIsLoadingCertificates] = useState(false);
  const [isLoadingStreams, setIsLoadingStreams] = useState(false);
  const [course, setCourse] = useState<ProjectsApiDerivedResponse | undefined>(
    undefined
  );
  const [certificates, setCertificates] = useState<
    CertificatesApiResponse | undefined
  >(undefined);
  const [streams, setStreams] = useState<StreamsApiResponse | undefined>(
    undefined
  );
  const [needsRefresh, setNeedsRefresh] = useState(false);
  const [needsRefreshOnNextRequest, setNeedsRefreshOnNextRequest] = useState(
    false
  );

  const refresh = useCallback(() => {
    setNeedsRefresh(true);
  }, []);

  const refreshOnNextRequest = useCallback(() => {
    setNeedsRefreshOnNextRequest(true);
  }, []);

  const refreshIfPreviouslyRequested = useCallback(() => {
    if (needsRefreshOnNextRequest) {
      setNeedsRefresh(true);
    }
  }, [needsRefreshOnNextRequest]);

  useEffect(() => {
    if (isLoggedIn) {
      if (course === undefined || needsRefresh) {
        setNeedsRefresh(false);
        setNeedsRefreshOnNextRequest(false);
        setIsLoadingCourse(true);
        setIsLoadingCertificates(true);
        (async () => {
          try {
            const [_course, _certificates] = await Promise.all([
              api.getProjects(),
              api.getCertificates(),
            ]);
            setCourse(_course);
            setCertificates(_certificates);
            return {
              course: _course,
              certificates: _certificates,
            };
          } finally {
            setIsLoadingCourse(false);
            setIsLoadingCertificates(false);
          }
        })();
      }
    } else {
      setIsLoadingCourse(false);
      setCourse(undefined);
      setCertificates(undefined);
      setNeedsRefresh(false);
      setNeedsRefreshOnNextRequest(false);
    }
  }, [course, certificates, isLoggedIn, api, needsRefresh]);

  useEffect(() => {
    if (streams === undefined) {
      setIsLoadingStreams(true);
      (async () => {
        try {
          const _streams = await api.getStreams();
          setStreams(_streams);
        } finally {
          setIsLoadingStreams(false);
        }
      })();
    }
  }, [streams, api]);

  const value = useMemo(
    () => ({
      isLoading: isLoadingCourse || isLoadingCertificates || isLoadingStreams,
      isLoadingCourse,
      isLoadingCertificates,
      isLoadingStreams,
      course,
      certificates,
      streams,
      refresh,
      refreshOnNextRequest,
      refreshIfPreviouslyRequested,
    }),
    [
      isLoadingCourse,
      isLoadingCertificates,
      isLoadingStreams,
      course,
      certificates,
      streams,
      refresh,
      refreshOnNextRequest,
      refreshIfPreviouslyRequested,
    ]
  );

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};

export function useStore() {
  const context = useContext(StoreContext);
  return context;
}
