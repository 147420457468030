import React, {
  useMemo,
  useRef,
  useEffect,
  useCallback,
  AnchorHTMLAttributes,
} from 'react';
import Link from 'next/link';
import url from 'url';
import { sendEvent } from '../lib/frontend/stats';
import { Category } from '../lib/universal/types';

type LinkUrl = React.ComponentProps<typeof Link>['href'];

const InstrumentedLink: React.FC<
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    category: Category;
    label: string;
    internalHref?: LinkUrl;
    extra?: { [key: string]: string | number | boolean | null };
    onClick?: (event: any) => void;
  }
> = ({
  category,
  label,
  internalHref,
  extra,
  onClick,
  children,
  href: originalHref,
  ...baseAnchorProps
}) => {
  const nodeRef = useRef<HTMLAnchorElement | null>(null);

  const href =
    internalHref !== undefined ? url.format(internalHref) : originalHref;

  const eventLabel = useMemo(
    () =>
      [label, href]
        .filter((x) => typeof x === 'string' && x.length > 0)
        .join(','),
    [label, href]
  );

  const onLeftClick = useCallback(
    (event) => {
      sendEvent(
        category,
        {
          name: 'leftClickLink',
          metadata: {
            label,
            url: href,
            extra,
          },
        },
        { event_label: eventLabel }
      );
      if (onClick) {
        onClick(event);
      }
    },
    [category, eventLabel, label, href, extra, onClick]
  );

  const onRightClick = useCallback(() => {
    sendEvent(
      category,
      {
        name: 'rightClickLink',
        metadata: {
          label,
          url: href,
          extra,
        },
      },
      { event_label: eventLabel }
    );
  }, [category, eventLabel, label, href, extra]);

  useEffect(() => {
    const el = nodeRef.current;
    if (el) {
      el.addEventListener('contextmenu', onRightClick);
    }
    return () => {
      if (el) {
        el.removeEventListener('contextmenu', onRightClick);
      }
    };
  }, [onRightClick]);

  const anchorProps = {
    ...baseAnchorProps,
    ...(internalHref !== undefined ? {} : { href }),
  };

  const anchorTag = (
    <a ref={nodeRef} onClick={onLeftClick} {...anchorProps}>
      {children}
    </a>
  );

  if (internalHref !== undefined) {
    return <Link href={internalHref}>{anchorTag}</Link>;
  } else {
    return anchorTag;
  }
};

export default InstrumentedLink;
