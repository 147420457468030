import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import InstrumentedLink from './InstrumentedLink';
import { CATEGORIES } from '../lib/universal/types';

import styles from './Footer.module.css';

const Footer: React.FC = () => {
  return (
    <Row className={styles.footer}>
      <Col>
        <Container>
          <Row className="justify-content-md-center text-right">
            <Col md="auto">&copy; supercooldata. All rights reserved</Col>
            <Col md="auto">
              <InstrumentedLink
                category={CATEGORIES.FOOTER}
                label="footer_terms"
                internalHref="/terms"
              >
                Terms and Conditions
              </InstrumentedLink>
            </Col>
            <Col md="auto">
              <InstrumentedLink
                category={CATEGORIES.FOOTER}
                label="footer_privacy"
                internalHref="/privacy"
              >
                Privacy Policy
              </InstrumentedLink>
            </Col>
            <Col md="auto">
              <InstrumentedLink
                category={CATEGORIES.FOOTER}
                label="footer_about"
                internalHref="/about"
              >
                About
              </InstrumentedLink>
            </Col>            
          </Row>
        </Container>
      </Col>
    </Row>
  );
};

export default Footer;
