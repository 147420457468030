import React from 'react';

import InstrumentedLink from './InstrumentedLink';
import { CATEGORIES } from '../lib/universal/types';
import styles from './Logo.module.css';

const Logo: React.FC<{ href?: string }> = ({ href = '/' }) => (
  <InstrumentedLink
    internalHref={href}
    label="logo_click"
    category={CATEGORIES.LOGO}
    className={styles.logo}
  >
    <span className={styles.logoText}>
      super.cool.data<span className={styles.logoTextSemicolon}>;</span>
    </span>
  </InstrumentedLink>
);

export default Logo;
