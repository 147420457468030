import { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import React, { useEffect } from 'react';
import getNextConfig from 'next/config';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'c3/c3.css';
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';
import '../styles/globals.css';
import { environment, isProd, config } from '../lib/frontend/config';
import { AuthProvider } from '../lib/frontend/auth';
import { StoreProvider } from '../lib/frontend/store';
import { sendEvent } from '../lib/frontend/stats'; // Never delete this import
import { CATEGORIES } from '../lib/universal/types';
import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';
import ErrorBoundary from '../components/ErrorBoundary';
import {
  LINK_VISIT_ID_KEY,
  replaceUrlWithoutParam,
} from '../lib/frontend/util';

if (
  config.sentry &&
  config.sentry.enabled &&
  typeof config.sentry.dsn === 'string'
) {
  const nextConfig = getNextConfig();
  const distDir = `${nextConfig.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: config.sentry.enabled,
    dsn: config.sentry.dsn,
    environment,
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          if (frame.filename !== undefined) {
            frame.filename = frame.filename.replace(distDir, 'app:///_next');
          }
          return frame;
        },
      }),
    ],
  });
}

const App: React.FC<AppProps> = (props) => {
  const router = useRouter();

  const { Component, pageProps } = props;

  // Workaround for https://github.com/vercel/next.js/issues/8592
  const err = 'err' in props ? (props as any).err : undefined;

  useEffect(() => {
    if (!isProd) {
      console.log(`Starting App with environment: ${environment}`);
    }
  }, []);

  useEffect(() => {
    const onRouteChangeComplete = (url: string) => {
      console.log('App router has changed to: ', url);
      replaceUrlWithoutParam(LINK_VISIT_ID_KEY);
      sendEvent(
        CATEGORIES.PAGE_METADATA,
        {
          name: 'changePageRouteComplete',
          metadata: {
            url,
          },
        },
        { event_label: url }
      );
      window.scrollTo(0, 0);
    };

    router.events.on('routeChangeComplete', onRouteChangeComplete);
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events]);

  return (
    <AuthProvider>
      <StoreProvider>
        <Component {...pageProps} err={err} />
      </StoreProvider>
    </AuthProvider>
  );
};

const AppWithErrorBoundary: typeof App = (props) => (
  <ErrorBoundary>
    <App {...props} />
  </ErrorBoundary>
);

export default AppWithErrorBoundary;
